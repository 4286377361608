import React from 'react';
import { CirclesWithBar } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className="audio-loader">
     <CirclesWithBar
  height="100"
  width="100"
  color="#4fa94d"
  outerCircleColor="#2563EB"
  innerCircleColor="#2563EB"
  barColor="#2563EB"
  ariaLabel="circles-with-bar-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
    </div>
  );
};

export default Loader;
